import React, { useContext, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { StoreContext } from "../store";
import * as Routes from "../routes";
import { IReport } from "../common/types";
import logo from "../assets/WaveBI_Logo_Text.svg";

import Toolbar from "@mui/material/Toolbar";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MessageIcon from "@mui/icons-material/Message";
import MenuIcon from "@mui/icons-material/Menu";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";

const drawerWidth = 240;
export interface LayoutProps {
  children: React.ReactNode;
  window?: () => Window;
}

export default function Layout(props: LayoutProps) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { state } = useContext(StoreContext);
  const [selectedReport, setSelectedReport] = useState<IReport>();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.reports && state.reports.length > 0) {
      setSelectedReport(state.reports[0]);
    }
  }, [state.reports]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // This function is triggered when the select changes
  const selectChange = (event: any) => {
    const reportID = event.target.value;
    const report = state.reports.filter((report) => {
      if (Number(reportID) === Number(report.id)) {
        return report;
      }
      return null;
    });

    if (report.length > 0) {
      const rep = report[0];
      setSelectedReport(rep);
      navigate(
        Routes.CLIENT_REPORT_VIEW_LINK(rep.organisation.slug, rep.instance.slug)
      );
    }
  };
  const drawer = (
    <>
      <Button href={process.env.REACT_APP_PORTAL_URL}>
        <img src={logo} alt="WaveBI Logo" />
      </Button>

      <Divider />

      <List component="nav">
        {state.user && state.user.role === "ADMIN" ? (
          <>
            <ListItemButton component={NavLink} to={Routes.ADMIN}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </ListItemButton>
            <Divider sx={{ my: 1 }} />
          </>
        ) : null}
        <Divider sx={{ my: 1 }} />
        <ListItem>
          <select
            onChange={selectChange}
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
              fontSize: 16,
              fontWeight: 500,
              maxWidth: "200px",
            }}
          >
            {state.reports.map((report) => {
              return (
                <option
                  style={{ border: "none", outline: "none", padding: 6 }}
                  value={report.id}
                >
                  {report.instance.name}
                </option>
              );
            })}
          </select>
        </ListItem>
        <Divider sx={{ my: 1 }} />
        {selectedReport ? (
          <>
            <ListItemButton
              component={NavLink}
              to={Routes.CLIENT_REPORT_VIEW_LINK(
                selectedReport.organisation.slug,
                selectedReport.instance.slug
              )}
            >
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="Report" />
            </ListItemButton>

            {selectedReport.reportLinks.map((reportLink, index) => {
              return (
                <>
                  <ListItemButton
                    component={NavLink}
                    key={reportLink.link}
                    to={Routes.CLIENT_DASHBOARD_VIEW_LINK(
                      selectedReport.organisation.slug,
                      selectedReport.instance.slug,
                      String(index + 1)
                    )}
                  >
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={reportLink.title} />
                  </ListItemButton>
                </>
              );
            })}
            <ListItemButton
              component={NavLink}
              to={Routes.FEEDBACK_VIEW_LINK(
                selectedReport.organisation.slug,
                selectedReport.instance.slug
              )}
            >
              <ListItemIcon>
                <ThumbUpAltIcon />
              </ListItemIcon>
              <ListItemText primary="Leave Feedback" />
            </ListItemButton>

            <ListItemButton
              component={NavLink}
              to={Routes.CONTACT_VIEW_LINK(
                selectedReport.organisation.slug,
                selectedReport.instance.slug
              )}
            >
              <ListItemIcon>
                <MessageIcon />
              </ListItemIcon>
              <ListItemText primary="Talk to us" />
            </ListItemButton>
          </>
        ) : null}
      </List>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", bgcolor: "#fafafa", minHeight: "100vh" }}>
      <CssBaseline />
      {/*   <h3>{selectedReport?.organisation.slug}</h3> */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label=""
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          pt: 2,

          width: {
            sm: `calc(100% - ${drawerWidth}px)`,
            maxWidth: "1260px",
            margin: "0px auto",
            height: "100%",
          },
        }}
      >
        <Toolbar />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        {props.children}
      </Box>
    </Box>
  );
}
