const Contact = () => {
  return (
    <div>
      <iframe
        width="100%"
        height="750px"
        src="https://bookings.probityweb.com.au/portal-embed#/4689047000000055018"
        style={{ border: "none" }}
        title="Contact WaveDXP"
      ></iframe>
    </div>
  );
};

export default Contact;
