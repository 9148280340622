export const HOME = "/";
export const ADMIN = "/admin";
export const CREATE_REPORT = "/admin/report/create";
export const EDIT_REPORT = "/admin/report/edit/:id";
export const CREATE_SUMMARY = "admin/summary/create/:report";
export const EDIT_SUMMARY = "admin/summary/edit/:report/:id";
export const CLIENT_REPORT_VIEW = "/:organisation/:instance/report";
export const FEEDBACK = "/:organisation/:instance/feedback";
export const CONTACT = "/:organisation/:instance/contact";
export const CLIENT_DASHBOARD_VIEW = "/:organisation/:instance/dashboard/:page";

export const CLIENT_REPORT_VIEW_LINK = (
  organisation: string,
  instance: string
) => `/${organisation}/${instance}/report`;
export const CLIENT_DASHBOARD_VIEW_LINK = (
  organisation: string,
  instance: string,
  page: string
) => `/${organisation}/${instance}/dashboard/${page}`;
export const FEEDBACK_VIEW_LINK = (organisation: string, instance: string) =>
  `/${organisation}/${instance}/feedback`;
export const CONTACT_VIEW_LINK = (organisation: string, instance: string) =>
  `/${organisation}/${instance}/contact`;
export const REPORT_EDIT_LINK = (id: string) => `/admin/report/edit/${id}`;
export const SUMMARY_CREATE_LINK = (instance: string) =>
  `/admin/summary/create/${instance}`;
export const SUMMARY_EDIT_LINK = (instance: string, id: string) =>
  `/admin/summary/edit/${instance}/${id}`;
